.customInput {
  width: 100%;
  background-color: #f7f7f9;
  border: none;
  border-radius: 6px;
  margin-top: 8px;
  font-size: 16px;
  color: #363b53;
  display: flex;
  align-self: flex-start;
  box-sizing: border-box;
  padding: 8px 6px;
}
.customInputSearch {
  width: 90%;
  background: transparent;
  border: none;
  font-size: 12px;
  color: #363b53;
  display: flex;
  align-self: flex-start;
  box-sizing: border-box;
  padding: 7px;
}
.customInputSearch::placeholder {
  color: #b5b7be;
}
.customInputSearch:focus {
  outline: none;
}
.searchContainer {
  border-radius: 2px;
}

.customInput::placeholder {
  color: #b5b7be;
}
.customInput:hover {
  /* border-bottom: 1px solid #b3b5b6; */

  border-radius: 6px 6px 0px 0px;
  box-shadow: inset 0px -502px 0px -500.5px #b3b5b6;
  background-color: #f7f7f9;
}
.customInput:focus {
  /* border-bottom: 1px solid #b3b5b6; */
  outline: none;

  border-radius: 6px 6px 0px 0px;
  box-shadow: inset 0px -502px 0px -500.5px #b3b5b6;
  background-color: #f7f7f9;
}
.suggestionBox {
  text-align: left;
  padding: 10px 2px;
}
