.rc-time-picker-input {
  border-color: #3c4242;
  font-size: 14px !important;
}
.rc-time-picker-panel {
  z-index: 9999 !important;
}

/* .rc-time-picker-input:hover {
  border-color: hotpink;
} */

.custom-time-picker {
  width: 83px;
  font-size: 14px;
}
.custom-time-picker-mobile {
  width: 70px;
  font-size: 12px !important;
}

.custom-time-picker .rc-time-picker-clear {
  display: none;
}

.custom-time-picker .scroller::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.react-datepicker__input-container input::placeholder {
  color: #b3b5b6; /* Change to your desired color */
}

.react-datepicker__input-container input:focus {
  border-color: "#F7F7F9";
  outline: none; /* Remove default focus outline if desired */
}

@media only screen and (max-width: 768px) {
  .rc-time-picker-input {
    border-color: #3c4242;
    font-size: 12px !important;
  }
}
