.custom-time-picker .rc-time-picker-input {
  font-size: 16px;
  padding: 20px 8px;
  border-radius: 8px;
  border: 1px solid rgb(209 213 219) !important;
  color: rgb(17 24 39);
}
.customPicker {
  width: 190px;
  border-color: hsl(0, 0%, 80%);
  /* placeholder: gray 500; */
  border: 1px solid #cbd5e0;
  color: #4b5563;
  font-size: 0.875rem; /* Equivalent to text-sm in Tailwind */
  border-radius: 0.375rem; /* Equivalent to rounded-md in Tailwind */
  display: block;
  padding-left: 0.625rem; /* Equivalent to pl-2.5 in Tailwind */
  padding: 0.5rem; /* Equivalent to p-2 in Tailwind */
}

.customPicker::placeholder {
  font-size: 16px;
  color: #b3b5b6;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
