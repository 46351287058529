/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");

.App {
  text-align: center;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Roboto", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #fefefe !important;
  color: -internal-light-dark(black, white) !important;
}

input:disabled {
  cursor: default;
  color: #3c4242;
  border-color: lightgray;
}
/* 
.react-calendar__navigation__label__labelText {
  font-size: "14px";
} */

/* .rc-time-picker-input {
  font-size: "14px";

} */
