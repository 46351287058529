#calendar {
  max-width: 900px;
  margin: 0 auto;
}

.fc .fc-toolbar-title {
  font-size: 22;
  color: #3c4242;
  margin: 0;
}
.fc-v-event .fc-event-title {
  top: 0;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
  margin-top: 1em;
}
/* prevent events to take less space */
/* .fc-timegrid-event-harness-inset {
  left: 0px !important;
  right: 0px !important;
} */
thead {
  border-width: 0;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: inherit;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: inherit;
}

.fc-day-today {
  color: #479ae9;
}

.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 0px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  border-radius: 0px;
}

.fc .fc-button-primary {
  color: inherit;
  background-color: #f7f7f9;
  border-color: #f7f7f9;
}

.fc .fc-button-primary:hover {
  background-color: inherit;
  color: #2c3e50;
  border: 0;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: inherit;
  background-color: inherit;
}

thead {
  border-width: 0;
  font-size: 14px;
}

table {
  border-color: #f7f7f9;
  border: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 0px solid var(--fc-border-color, #ddd);
}

.fc-col-header-cell .fc-day {
  border-width: 0;
}

thead .fc-scroller {
  overflow: hidden !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px solid var(--fc-border-color, #ddd);
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
  border: 1px solid var(--fc-border-color, #ddd);
}

.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
  border: 1px solid var(--fc-border-color, #ddd);
}

.fc .fc-daygrid-day-number {
  font-size: 18px;
}

.fc .fc-timegrid-col-frame {
  border: 1px solid var(--fc-border-color, #ddd);
  border-left: 0px;
}

.fc .fc-timegrid-slot {
  border-bottom: 1px solid var(--fc-border-color, #ddd);
}

.fc .fc-timegrid-slot-label {
  border-bottom: 0px !important;
  border-top: 0px !important;
  font-size: 11px;
  font-weight: 500;
  vertical-align: top;
}

.fc-timegrid-col-frame .fc-timegrid-now-indicator-container {
  border-top: 0px !important;
}

.fc .fc-bg-event {
  background: #07135d;
  opacity: 1;
}
/* .fc .fc-bg-event:hover {
  border: 2px solid rgb(106, 208, 248);
} */

.fc-col-header-cell-cushion {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.MuiAvatar-root-MuiAvatarGroup-avatar {
  background-color: "pink";
}
