/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

* {
  font-family: "Roboto", sans-serif;
}
.dot {
  position: absolute;
  background-color: #f32d2d;
  width: 14px;
  height: 14px;
  top: 2px;
  right: 8px;
  border-radius: 7px;
}
.notifyContainer {
  width: 376px;
  /* max-height: 60vh; */
  background-color: #fbfbff;
  position: absolute;
  z-index: 999;
  right: 25px;
  top: 50px;
  padding: 25px 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.15);
}
.content {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.smNotifyContainer {
  width: 324px;
  /* max-height: 60vh; */
  background-color: #fbfbff;
  position: absolute;
  z-index: 999;
  right: -55px;
  padding: 25px 0px;
  top: 50px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.15);
}

.profileContainer {
  width: 230px;
  /* max-height: 60vh; */
  position: absolute;
  z-index: 999;
  right: 70px;
  top: 45px;
  padding: 16px 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.15);
}
.profileContainerMobile {
  right: 50px;
}

/* .notifyContainer::-webkit-scrollbar {
  display: none;
} */
.notifyContainer::before,
.profileContainer::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;
  right: -10px;
  border: 10px solid transparent;
  border-top: 13px solid #fbfbff;
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(3px 1px 1px rgba(0, 0, 0, 0.15));
}
.badge {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.insideNotify {
  align-items: center;
  width: 100%;
  display: flex;
  padding: 2px 0px;
}
.insideProfile {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* to remove scrollbar */
/* .upper::-webkit-scrollbar {
  width: 10; 
  background: "transparent"; 
} */
.unread {
  background-color: #df6054;
  width: 14px;
  height: 14px;
  margin-left: 6px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upper::-webkit-scrollbar {
  width: 16px;
}

.upper::-webkit-scrollbar-track {
  /* background: #f5f5f5; */
  background: none;
  border-radius: 10px;
}

.upper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 30px;
  background-color: #c1c1c1;
  border: 4px solid transparent;
  background-clip: padding-box;
}

.upper::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  height: 30px;
  background-color: #bcbbbb;
  border: 4px solid transparent;
  background-clip: padding-box;
}
.link {
  text-decoration: none;
  color: #000;
  width: 100%;
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
}
