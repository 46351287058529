.calendar-style {
  position: absolute;
  top: 60px;
  right: 0;
  background: #f7f7f9;
  border: none;
  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  box-shadow: 0px 4px 10px rgb(30 30 30 / 8%);
}
.calendar-style .react-calendar__tile--active {
  background: #479ae9;
  color: #fff;
}
.calendar-style .react-calendar__tile--active:hover {
  background-color: transparent;
  color: blue;
}
.react-calendar__navigation button {
  font-weight: 700;
}
.react-calendar__month-view__days__day--weekend {
  color: #363b53;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-weight: 700;
  font-size: 25px;
  order: 2;
}
.react-calendar__navigation__prev-button {
  position: absolute;
  top: 15px;
}
.react-calendar__navigation__next-button {
  position: absolute;
  right: 0;
  top: 15px;
}
.react-calendar__navigation__label__labelText {
  font-size: 14px;
  font-weight: 500;
}

.react-calendar__navigation__label {
  flex-grow: 0;
}

.react-calendar__tile--now {
  background-color: transparent;
  color: #479ae9;
}

.react-calendar__viewContainer {
  font-size: 12px;
}

.react-calendar__tile {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .calendar-style {
    position: absolute;
    top: 50px;
    left: 0;
    background: #f7f7f9;
    border: none;
    padding: 10px;
    border-radius: 10px;
    z-index: 999;
    box-shadow: 0px 4px 10px rgb(30 30 30 / 8%);
  }
}
