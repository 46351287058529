.root::-webkit-scrollbar {
  width: 16px;
}

.root::-webkit-scrollbar-track {
  /* background: #f5f5f5; */
  background: none;
  border-radius: 10px;
}

.root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 30px;
  background-color: #bcbbbb;
  border: 4px solid transparent;
  background-clip: padding-box;
}

.root::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  height: 30px;
  background-color: #bcbbbb;
  border: 4px solid transparent;
  background-clip: padding-box;
}
